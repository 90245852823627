import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Constants } from '@app/constants';
import { environment } from '@environment/environment';
import { Event } from '@model/event';
import { EventSetting } from '@model/event-setting';
import { AppDataService } from '@service/app-data.service';
import { GenericHttpService } from '@service/generic-http.service';
import { catchError, Observable, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventService extends GenericHttpService {
  /* eslint-disable */
  private httpClient = inject(HttpClient);
  private appDataService = inject(AppDataService);

  private baseUrl = `${environment.url}/events`;
  /* eslint-enable */

  constructor() {
    super();

    // Create a timer to update the event settings
    timer(Constants.eventSettingsUpdateInterval, Constants.eventSettingsUpdateInterval)
      .pipe(
        switchMap(() => this.updateEventSettings()),
      )
      .subscribe(_ => console.log('Updated Event Settings'));
  }

  initialize(): Observable<void> {

    return this.httpClient.get<Event>(`${this.baseUrl}/${Constants.eventCode}/active`)
      .pipe(
        switchMap(event => this.appDataService.updateEventAndSettings(event)),
        tap(() => console.log('Initialized EventService')),
        catchError(error => {
          this.handleError(error);
          throw error;
        }),
      );
  }

  // Use this new initialize() function if backend accepts POST on endpoint event/active with device info as body
  // async newInitialize(): Promise<void> {
  //   const deviceInfo = await this.clientService.get();

  //   return firstValueFrom(this.httpClient.post<Event>(`${this.baseUrl}/${Constants.eventCode}/active`, deviceInfo)
  //     .pipe(
  //       concatMap(event => this.updateEventSettings(event)),
  //       catchError(error => {
  //         this.handleError(error);
  //         throw error;
  //       }),
  //     ));
  // }

  private updateEventSettings(): Observable<void> {
    const event = this.appDataService.event();

    return this.httpClient.get<EventSetting[]>(`${this.baseUrl}/${event.uuid}/settings`)
      .pipe(
        map(settings => {
          this.appDataService.updateEventAndSettings({
            ...event,
            settings,
          });

          return void 0;
        }),
        catchError(error => {
          this.handleError(error);
          throw error;
        }),
      );
  }
}
